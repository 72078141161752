@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  color-scheme: light dark;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.overflow-y-auto::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.overflow-y-auto {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

/* Dark mode styles */
.dark {
  color-scheme: dark;
  background-color: #000000;
}

/* Light mode styles */
.light {
  color-scheme: light;
  background-color: #FFFFFF;
}

/* Ensure proper stacking context */
#root {
  isolation: isolate;
  min-height: 100vh;
  background-color: inherit;
}
